import React from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Picture from '@c/Picture';

const useStyles = makeStyles(theme => ({
  gridItem: {
    '& > picture': {
      display: 'inherit',
      alignItems: 'inherit',
      justifyContent: 'inherit',
    },
  },
}));

const MediaGrid = ({ data }) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm')),
    classes = useStyles(),
    topLeft = data?.topLeft && data?.topLeft[0],
    topRight = data?.topRight && data?.topRight[0],
    bottomLeft = data?.bottomLeft && data?.bottomLeft[0],
    bottomRight = data?.bottomRight && data?.bottomRight[0];

  return (
    <Grid container spacing={isSmall ? 4 : 5}>
      <Grid
        container
        item
        xs={6}
        alignItems='flex-end'
        justify='flex-end'
        className={classes.gridItem}
      >
        {topLeft && <Picture width='auto' maxWidth='100%' alt={topLeft.title} {...topLeft} />}
      </Grid>
      <Grid container item xs={6} alignItems='flex-end' className={classes.gridItem}>
        {topRight && (
          <Picture
            width='auto'
            maxWidth={isSmall ? '100%' : '82.5%'}
            alt={topRight.title}
            {...topRight}
          />
        )}
      </Grid>
      <Grid
        container
        item
        xs={6}
        alignItems='flex-start'
        justify='flex-end'
        className={classes.gridItem}
      >
        {bottomLeft && (
          <Picture
            width='auto'
            maxWidth={isSmall ? '100%' : '65%'}
            alt={bottomLeft.title}
            {...bottomLeft}
          />
        )}
      </Grid>
      <Grid container item xs={6} alignItems='flex-start' className={classes.gridItem}>
        {bottomRight && (
          <Picture width='auto' maxWidth='100%' alt={bottomRight.title} {...bottomRight} />
        )}
      </Grid>
    </Grid>
  );
};

export default MediaGrid;

MediaGrid.propTypes = {
  data: PropTypes.object.isRequired,
};
