import React, { useMemo, useCallback } from 'react';
import { graphql, Link } from 'gatsby';
import { transparentize } from 'polished';
import ChevronLeftIcon from '@assets/chevron-left.svg';
import { Container, Grid, Box, Button } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { PageTypeContextProvider, PAGE_TYPE_ARTICLE } from '@context/PageTypeContext';
import { useColorMode } from '@context/ColorModeContext';
import { SET_COLOR_MODE } from '@context/ColorModeContext/actions';
import { EntryContextProvider } from '@context/EntryContext';
import Layout from '@c/Layout';
import ArticleContent from '@c/ArticleContent';
import VisibilitySensor from '@c/VisibilitySensor';
import MediaGrid from '@c/MediaGrid';
import SellingPoints from '@c/SellingPoints';
import Divider from '@c/Divider';
import FormHandler from '@c/forms';

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    lineHeight: 1.33,
    letterSpacing: '0.5px',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  link: {
    color: theme.colors.dark,
    padding: 0,
    '&:focus': {
      color: transparentize(0.32, theme.colors.dark),
      '& .MuiButton-startIcon svg path': {
        stroke: transparentize(0.32, theme.colors.dark),
      },
    },
  },
}));

const CareerListing = ({
  data: {
    cms: { entry, careerMainPage, seomatic },
  },
  pageContext: { slug },
}) => {
  const classes = useStyles(),
    theme = useTheme(),
    [, setBackground] = useColorMode(),
    title = entry.title,
    datePosted = entry.dateCreated,
    articleContent = entry.articleContent,
    form = entry.form,
    mediaGrid = careerMainPage.mediaGrid[0],
    sellingPoints = careerMainPage.sellingPoints,
    careerMainPageTitle = careerMainPage.title;

  const seomaticMetaJsonLdContainer = useMemo(() => JSON.parse(seomatic.metaJsonLdContainer), [
    seomatic.metaJsonLdContainer,
  ]);

  const modifiedSeomatic = useMemo(
    () => ({
      ...seomatic,
      asArray: true,
      metaJsonLdContainer: JSON.stringify({
        ...seomaticMetaJsonLdContainer,
        mainEntityOfPage: {
          ...seomaticMetaJsonLdContainer?.mainEntityOfPage,
          title,
          datePosted,
          hiringOrganization: {
            '@type': 'Organization',
            name: seomaticMetaJsonLdContainer?.identity?.name || '',
            sameAs: seomaticMetaJsonLdContainer?.identity?.url,
            logo: seomaticMetaJsonLdContainer?.identity?.image || '',
          },
          jobLocation: {
            '@type': 'Place',
            address: seomaticMetaJsonLdContainer?.identity?.address || '',
          },
        },
      }),
    }),
    [datePosted, seomatic, seomaticMetaJsonLdContainer, title]
  );

  const handleOnVisible = useCallback(
    id => {
      setBackground({
        type: SET_COLOR_MODE,
        background: { color: theme.colors.bg },
        header: { mode: 'light' },
        visibility: { visibleContentId: id },
      });
    },
    [setBackground, theme.colors.bg]
  );

  return (
    <PageTypeContextProvider value={PAGE_TYPE_ARTICLE}>
      <EntryContextProvider entry={entry}>
        <Layout seomatic={modifiedSeomatic}>
          <VisibilitySensor id={`career-listing-1-${slug}`} onVisible={handleOnVisible}>
            <Box mt={{ xs: 12, md: 19.5 }}>
              <Container>
                <Grid container justify='center'>
                  <Grid item xs={12} md={10} lg={2}>
                    <Box display='flex'>
                      <Button
                        size='small'
                        startIcon={<ChevronLeftIcon />}
                        component={Link}
                        to={`/${careerMainPage.uri}`}
                        className={classes.link}
                      >
                        {careerMainPageTitle}
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={10} lg={8}>
                    <div className={classes.label}>{title}</div>
                  </Grid>
                  <Grid item xs={12} lg={2}></Grid>
                </Grid>
              </Container>
            </Box>
          </VisibilitySensor>

          <ArticleContent content={articleContent} />

          <VisibilitySensor id={`career-listing-2-${slug}`} onVisible={handleOnVisible}>
            <Box>
              <Container>
                <Grid container justify='center'>
                  <Grid item xs={12} md={10} lg={8}>
                    <Box mt={{ xs: 7, mb: 12 }} mb={{ xs: 12, mb: 19.5 }}>
                      <FormHandler form={form} careerListingTitle={title} />
                    </Box>
                  </Grid>

                  {mediaGrid && (
                    <Grid item xs={12} md={11}>
                      <MediaGrid data={mediaGrid} />
                    </Grid>
                  )}

                  {(mediaGrid || (sellingPoints && !!sellingPoints.length)) && (
                    <Grid item xs={12}>
                      <Divider mt={{ xs: 12, md: 19.5 }} />
                    </Grid>
                  )}

                  {sellingPoints && !!sellingPoints.length && (
                    <Grid item xs={12}>
                      <Box
                        mt={{ xs: 12, md: 19.5 }}
                        mb={{ xs: 19.5, md: 32 }}
                        pl={{ xs: 0, md: 8 }}
                      >
                        <Box mb={{ xs: 5, md: 12 }}>
                          <h2>Hvorfor Norse</h2> {/* TODO: fetch from backend? */}
                        </Box>
                        <SellingPoints data={sellingPoints} />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Container>
            </Box>
          </VisibilitySensor>
        </Layout>
      </EntryContextProvider>
    </PageTypeContextProvider>
  );
};

export default CareerListing;

export const query = graphql`
  query($slug: String!, $path: String!) {
    cms {
      entry(section: "careerListings", slug: [$slug]) {
        ... on cms_careerListings_careerListing_Entry {
          title
          dateCreated
          articleContent {
            ...articleContentFields
          }
          form {
            ...formFields
          }
        }
      }
      careerMainPage: entry(section: "career") {
        title
        uri
        ... on cms_career_career_Entry {
          mediaGrid {
            ...mediaGridFields
          }
          sellingPoints {
            ...sellingPointsFields
          }
        }
      }
      seomatic(uri: $path, asArray: true) {
        ...seomaticFields
      }
    }
  }
`;
